<app-fluid-footer-template class="d-flex flex-fill" [hintPresent]="page?.moduleFlowPage?.hint" [selectedViewType]="selectedViewType">
  <ng-container appPageContent #fileTemplate>
    <div *ngIf="isHidden" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users [text]="hiddenMessage"></app-hide-from-end-users>
    </div>
    <div *ngIf="hasAnyWarnings" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
      <app-hide-from-end-users [text]="warningMessage"></app-hide-from-end-users>
    </div>
    <ey-froala-content-wrapper [editorContent]="this.page?.moduleFlowPage.body"></ey-froala-content-wrapper>
    <div *ngIf="flowPage.mode === 1" class="mt-10 mb-20 d-flex flex-fill">
      <ey-file-upload
        *ngIf="!flowPage.connectedTemplate?.prepopulatedTemplate"
        class="d-block flex-fill"
        filesSubTitle="Uploaded Files"
        requiredErrMsg="Upload Excel file to proceed."
        [fileExt]="fileExtension"
        [maxFiles]="1"
        [maxSize]="64"
        [files]="files"
        (initFileUpload)="upload($event)"
        (deleteFile)="deleteFile($event)"
        [showVisibilityColumn]="false"
        [dragAndDropTitle]="dragAndDropTitle"
        [dragAndDropSubTitle]="dragAndDropSubTitle"
        [hasError]="throwError"
        [isRequired]="isRequired"
        [docType]="docType"
        [allowFileDownload]="true"
        (initFileDownload)="downloadFile($event)"
        [decreaseFixedWidth]="checkCustomWidth"
      >
        <p class="doc-type-des m-0 p-0"></p>
      </ey-file-upload>
      <p *ngIf="!!flowPage.connectedTemplate?.prepopulatedTemplate">
        Using prepopulated template <b>{{ flowPage.connectedTemplate?.prepopulatedTemplate.name }}</b>
      </p>
    </div>
    <div *ngIf="flowPage.mode === 2" class="d-flex">
      <ey-button
        class="mt-20"
        icon="icon-ico_download"
        text="Download Excel File"
        [btnClass]="btnClass.primary"
        (click)="downloadOutFile()"
      ></ey-button>
      <div class="flex-grow-1"></div>
    </div>
    <div *ngIf="hasAnyErrors || hasAnyWarnings" class="mt-20">
      <p>Unable to proceed with the file. Fix following issues to continue.</p>
      <p *ngFor="let error of excelAIPageErrors" class="page-error flex-container">
        <i class="emp-icon icon-ico_error_toast"></i>
        <span>{{ error }}</span>
      </p>
      <ng-container *ngIf="hasAnyWarnings">
        <span class="warning"> Warnings:</span>
        <p *ngFor="let warning of excelAIpageWarnings" class="page-warning">- {{ warning }}</p>
      </ng-container>
    </div>
    <div *ngIf="hasAnyWarnings">
      <span class="warning-note">{{ warningNote }}</span>
    </div>
  </ng-container>
  <ng-container appPageFooter>
    <div>
      <div class="d-flex">
        <ey-button class="ms-auto me-20" [btnClass]="btnClass.btnPrev" text="Prev" (click)="onPrev()"></ey-button>
        <ey-button [btnClass]="btnClass.btnNext" text="Next" (click)="onNext()"></ey-button>
      </div>
      <div *ngIf="lastTechReviewDate && !isMobileBreakpoint">
        <span class="reviewDate spacing">Last Technical Review: {{ lastTechReviewDate }}</span>
      </div>
    </div>
  </ng-container>
</app-fluid-footer-template>
